export const STATUS_MESSAGE = {
  DOCUMENT: {
    UNDER_REVIEW:
      "We appreciate your patience as we review your documents; an  administrator will be in contact with you soon.",
    CREATE_NFT: "Approved ! Creating NFT Soon 😊",
    BLOCK:
      "You have been blocked by the administrator; please get in touch with our help desk.",
    REJECTED:
      "You are no longer able to upload documents since the admin rejected you.",
    COMPLETED:
      "We are happy 😊 to announce that after reviewing your documents. You have successfullly based the KYC. We will be providing NFT Soon on the wallet address you have resigtered.",
  },
};
