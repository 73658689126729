import axios from "axios";
import { authRequest, doneSuccess, getError } from "./user-slice";

export const getUserProfileData = (token) => async (dispatch) => {
  dispatch(authRequest());

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}users/get-user-profile`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result.data && result.status === 200) {
      dispatch(doneSuccess(result.data));
    }
  } catch (error) {
    dispatch(getError(error));
  }
};

// export const fetchUserCouponData = (token) => async (dispatch) => {
//   dispatch(authRequest());

//   try {
//     const result = await axios.get(
//       `${process.env.REACT_APP_API_BASE_URL}users/checkCouponForUser`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     if (result.data && result.status === 200) {
//       dispatch(doneSuccess(result.data));
//     }
//   } catch (error) {
//     dispatch(getError(error));
//   }
// };
