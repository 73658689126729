import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Toaster } from "react-hot-toast";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DocumentDarkImage from "assets/images/icons/timer.png";
import ProgressBar from "components/ProgressBar";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
function VerificationStatus() {
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails);
    }
  }, [userDetails]);
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_BASE_URL}users/get-user-profile`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       const results = await response.json();

  //       setUserData(results.data);
  //     } catch (error) {
  //       const errorMessage =
  //         error.response?.data?.message || "Upload failed. Please try again.";
  //       console.log(errorMessage);
  //     }
  //   };

  //   if (!token) {
  //     navigate("/");
  //   } else {
  //     fetchUserData();
  //   }
  // }, [token, navigate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster />
      <MDBox pt={6} pb={3}>
        <ProgressBar activeStep={5} />
        <Grid container spacing={6} mt={1}>
          <Grid item xs={12}>
            {!isMobile ? (
              <MDBox display="flex" justifyContent="center" mb={2}>
                <img alt="document" src={DocumentDarkImage} />
              </MDBox>
            ) : (
              <MDBox display="flex" justifyContent="center" mb={2}>
                <img alt="document" src={DocumentDarkImage} width="25%" />
              </MDBox>
            )}
            <MDBox
              textAlign="center"
              sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
            >
              <MDBox>
                <MDTypography variant="h1">
                  {" "}
                  <MDBox textAlign="center">
                    <MDTypography variant="h3">
                      {" "}
                      {userData && userData.status
                        ? userData.status === "Under_review"
                          ? "Under Review"
                          : userData.status === "Processing"
                          ? "Approved ! Creating NFT Soon 😊"
                          : userData.status
                        : "Document Submission Incomplete"}
                    </MDTypography>
                  </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography
                  variant="h6"
                  color="dark"
                  fontWeight="regular"
                  mt={3}
                >
                  {userData && userData.description
                    ? userData.description
                    : "   Your profile is currently under process. We will get back to you later."}
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default VerificationStatus;
