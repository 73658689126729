import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import toast, { Toaster } from "react-hot-toast";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DocumentDarkImage from "assets/images/payment.png";
import ErrorDialog from "components/Dialog/error";
import SuccessDialog from "components/Dialog/success";
import { useNavigate } from "react-router-dom";
import ProgressBar from "components/ProgressBar";
import TextField from "@mui/material/TextField";
import "./custom.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CoinImage from "assets/images/icons/coin.png";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import { STATUS_MESSAGE } from "constants";

function Payment() {
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);
  const [userPaymentData, setUserPaymentData] = useState(null);
  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formError, setFormError] = useState(false);
  const [hash, setHash] = useState("");

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    }
  }, [dispatch, token]);

  const fetchUserCouponData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/checkCouponForUser`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const results = await response.json();
      setUserPaymentData(results.data);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Upload failed. Please try again.";
      console.log(errorMessage);
    }
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        navigate("/");
        return;
      }
      await fetchUserCouponData();
    };
    fetchData();
  }, [
    token,
    navigate,
    fetchUserCouponData,
    // isDataFetched,
  ]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const validateHash = (input) => {
    console.log(hash);
    const hashRegex = /^[a-fA-F0-9]{64}$/;
    return hashRegex.test(input);
  };

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(process.env.REACT_APP_TRANSACTIONCODE)
      .then(() => {
        toast.success("Address copied to clipboard!");
      });
  };

  const checkDocumentStatus = () => {
    // if (!userData || userData.stage !== "Completed") {
    //   return false;
    // }

    if (userData?.isResubmitted === true && userData?.isPayment === true) {
      setTitleDialogMessage("Success");
      setSuccessMessage(
        "You don't have to worry about payment because you've already made it."
      );
      setIsSuccessDialogOpen(true);
      return false;
    }

    const validations = [
      {
        condition: userData?.isDocument === false,
        message:
          "You must submit the passport document before you can proceed for payment.",
        title: "Document Upload Incomplete",
      },
      {
        condition: userData?.isFace === false,
        message:
          "Before making a payment, you must complete face identification.",
        title: "Face Verification Incomplete",
      },
      {
        condition: userData?.isPhoto === false,
        message: "Before making a payment, a photo must be uploaded.",
        title: "Photo Upload Incomplete",
      },
    ];

    console.log(validations);

    for (const validation of validations) {
      if (validation.condition) {
        setIsErrorDialogOpen(true);
        setErrorMessage(validation.message);
        setTitleDialogMessage(validation.title);
        return false;
      }
    }

    const statusActions = {
      Under_review: {
        dialogType: "success",
        title: "Under Review",
        message: STATUS_MESSAGE.DOCUMENT.UNDER_REVIEW,
      },
      Processing: {
        dialogType: "success",
        title: "Creating NFT",
        message: STATUS_MESSAGE.DOCUMENT.CREATE_NFT,
      },
      Block: {
        dialogType: "error",
        title: "Block",
        message: STATUS_MESSAGE.DOCUMENT.BLOCK,
      },
      Rejected: {
        dialogType: "error",
        title: "Rejected",
        message: STATUS_MESSAGE.DOCUMENT.REJECTED,
      },
      Completed: {
        dialogType: "success",
        title: "Completed",
        message: STATUS_MESSAGE.DOCUMENT.COMPLETED,
      },
    };

    const action = statusActions[userData?.status];
    if (action) {
      if (action.dialogType === "success") {
        setIsSuccessDialogOpen(true);
        setTitleDialogMessage(action.title);
        setSuccessMessage(action.message);
      } else {
        setIsErrorDialogOpen(true);
        setTitleDialogMessage(action.title);
        setErrorMessage(action.message);
      }
      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    // console.log("Please enter a valid transaction", hash);
    event.preventDefault();
    if (!checkDocumentStatus()) {
      return;
    }

    if (!validateHash(hash)) {
      setFormError(true);
      setErrorMessage(
        "Please enter a valid transaction hash (minimum 64 characters)."
      );
      setTitleDialogMessage("Invalid Transaction Hash");
      setIsErrorDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/payment`,
        {
          method: "POST",
          body: JSON.stringify({ hash: hash }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        await response.json();
        setTitleDialogMessage("Success");
        setSuccessMessage(
          "I'm thankful. We have successfully sent your payment request. Please wait a little while; we will get back to you."
        );
        setIsSuccessDialogOpen(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Unknown error occurred");
        setTitleDialogMessage("Something's went wront");
        setIsErrorDialogOpen(true);
      }
    } catch (error) {
      setErrorMessage("An error occurred during submission. Please try again.");
      setTitleDialogMessage("Submission Error");
      setIsErrorDialogOpen(true);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Toaster />
      <MDBox pt={6} pb={3}>
        <ProgressBar activeStep={4} />
        {userPaymentData && userPaymentData.code !== 0 ? (
          <>
            {" "}
            <Grid container spacing={6} mt={1}>
              <Grid item xs={12}>
                {!isMobile ? (
                  <MDBox textAlign="center" mb={2}>
                    <img
                      alt="document"
                      src={CoinImage}
                      style={{ cursor: "pointer", width: 130 }}
                    />
                  </MDBox>
                ) : (
                  <MDBox textAlign="center" mb={2}>
                    <img
                      alt="document"
                      src={CoinImage}
                      style={{ cursor: "pointer", width: "30%" }}
                    />
                  </MDBox>
                )}

                <MDBox
                  textAlign="center"
                  sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h3" style={{ color: "green" }}>
                      {" "}
                      Coupon Applied Successfully 😊, Enjoy the ICB KYC without
                      Payment
                    </MDTypography>{" "}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={6} mt={1}>
              <Grid item xs={12}>
                <MDBox textAlign="center" mb={2}>
                  {!isMobile ? (
                    <img
                      alt="document"
                      src={DocumentDarkImage}
                      style={{ cursor: "pointer", width: 130 }}
                    />
                  ) : (
                    <img
                      alt="document"
                      src={DocumentDarkImage}
                      style={{ cursor: "pointer", width: "35%" }}
                    />
                  )}

                  <MDBox
                    textAlign="center"
                    mb={2}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <MDTypography variant="h6" sx={{ mr: 1 }}>
                      {process.env.REACT_APP_TRANSACTIONCODE}
                    </MDTypography>
                    <Tooltip title="Copy">
                      <IconButton onClick={handleCopy} aria-label="copy">
                        {" "}
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </MDBox>
                </MDBox>
                <MDBox
                  textAlign="center"
                  sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
                >
                  <MDBox mb={2}>
                    <MDTypography variant="h1" sx={{ fontSize: "2rem" }}>
                      {" "}
                      Open any wallet and pay on this address
                    </MDTypography>{" "}
                    <MDTypography
                      variant="h3"
                      color="error"
                      className="animated-text"
                      sx={{
                        mt: 2,
                      }}
                    >
                      {" "}
                      Pay USDT on Tron Blockchain
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    textAlign="center"
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                      justifyContent: "center",
                      "& .MuiTextField-root": {
                        m: 2,
                        width: { xs: "100%", sm: "50ch" },
                      },
                    }}
                  >
                    <TextField
                      required
                      id="outlined-required"
                      placeholder="Paste your transaction hash here"
                      label="Transaction Hash"
                      focused
                      error={formError}
                      helperText={
                        formError && "Please enter the transaction hash code"
                      }
                      value={hash}
                      onChange={(e) => setHash(e.target.value)}
                    />

                    <MDButton
                      type="submit"
                      variant="contained"
                      size="large"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   if (!checkDocumentStatus()) {
                      //     handleSubmit(e);
                      //   }
                      // }}
                      sx={{
                        background:
                          "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "darkgreen",
                        },
                        m: 2,
                        mt: 1,
                      }}
                    >
                      Submit
                    </MDButton>
                  </MDBox>

                  <ErrorDialog
                    open={isErrorDialogOpen}
                    handleClose={handleDialogClose}
                    title={titleDialogMessage}
                    message={errorMessage}
                  />
                  <SuccessDialog
                    open={isSuccessDialogOpen}
                    handleClose={handleDialogSuccessClose}
                    title={titleDialogMessage}
                    message={successMessage}
                    redirectUrl="/view-status"
                  />
                </MDBox>
              </Grid>
            </Grid>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Payment;
