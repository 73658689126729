import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DocumentDarkImage from "assets/images/icons/Document-1.png";
import ErrorDialog from "components/Dialog/error";
import SuccessDialog from "components/Dialog/success";
import ProgressBar from "components/ProgressBar";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfileData } from "../redux/slice/user-handle";
import { STATUS_MESSAGE } from "constants";

function Documents() {
  const token = localStorage.getItem("token");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [titleDialogMessage, setTitleDialogMessage] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [userData, setUserData] = useState(null);

  const dispatch = useDispatch();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (token) {
      dispatch(getUserProfileData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (userDetails !== null) {
      setUserData(userDetails);
    }
  }, [userDetails]);

  const handleDialogClose = () => {
    setIsErrorDialogOpen(false);
  };

  const handleDialogSuccessClose = () => {
    setIsSuccessDialogOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      setErrorMessage(
        "Please upload a passport in .PDF format for verification"
      );
      setTitleDialogMessage("Document Rejected");
      setIsErrorDialogOpen(true);
    }
  };

  const triggerFileSelection = () => {
    fileInputRef.current.click();
  };

  const checkDocumentStatus = () => {
    if (!userData || userData.stage !== "Completed") {
      return false;
    }

    const statusActions = {
      Under_review: {
        dialogType: "success",
        title: "Under Review",
        message: STATUS_MESSAGE.DOCUMENT.UNDER_REVIEW,
      },
      Processing: {
        dialogType: "success",
        title: "Creating NFT",
        message: STATUS_MESSAGE.DOCUMENT.CREATE_NFT,
      },
      Block: {
        dialogType: "error",
        title: "Block",
        message: STATUS_MESSAGE.DOCUMENT.BLOCK,
      },
      Rejected: {
        dialogType: "error",
        title: "Rejected",
        message: STATUS_MESSAGE.DOCUMENT.REJECTED,
      },
      Completed: {
        dialogType: "success",
        title: "Completed",
        message: STATUS_MESSAGE.DOCUMENT.COMPLETED,
      },
    };

    const action = statusActions[userData.status];

    if (action) {
      if (action.dialogType === "success") {
        setIsSuccessDialogOpen(true);
        setTitleDialogMessage(action.title);
        setSuccessMessage(action.message);
      } else {
        setIsErrorDialogOpen(true);
        setTitleDialogMessage(action.title);
        setErrorMessage(action.message);
      }
      return true;
    }

    return false;
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setTitleDialogMessage("Document Rejected");
      setErrorMessage("Please select a document before uploading.");
      setIsErrorDialogOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}users/upload-document`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        await response.json();
        setTitleDialogMessage("Document Submitted");
        setSuccessMessage("The document was successfully submitted.");
        setIsSuccessDialogOpen(true);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Unknown error occurred");
        setIsErrorDialogOpen(true);
        setTitleDialogMessage("Document Rejected");
      }
    } catch (error) {
      const errorMessage = error.message || "Upload failed. Please try again.";
      setErrorMessage(errorMessage);
      setTitleDialogMessage("Document Rejected");
      setIsErrorDialogOpen(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <ProgressBar activeStep={1} />
        <Grid container spacing={6} mt={1}>
          <Grid item xs={12}>
            {!isMobile ? (
              <MDBox display="flex" justifyContent="center" mb={2}>
                <img alt="document" src={DocumentDarkImage} />
              </MDBox>
            ) : (
              <MDBox display="flex" justifyContent="center">
                <img alt="document" src={DocumentDarkImage} width="35%" />
              </MDBox>
            )}

            <input
              type="file"
              ref={fileInputRef}
              accept="application/pdf"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <MDBox
              textAlign="center"
              sx={!isMobile ? { marginTop: 10 } : { marginTop: 5 }}
            >
              <MDBox>
                <MDTypography variant="h1" sx={{ fontSize: "2rem" }}>
                  Document Verification
                </MDTypography>
              </MDBox>
              <MDBox mb={2}>
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                >
                  Please upload passport in .PDF format for Verification
                </MDTypography>
              </MDBox>

              {selectedFile ? (
                <MDBox>
                  <MDButton
                    variant="contained"
                    size="large"
                    onClick={handleFileUpload}
                    sx={{
                      background:
                        "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "darkgreen",
                      },
                    }}
                  >
                    UPLOAD
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox>
                  <MDButton
                    variant="contained"
                    size="large"
                    onClick={() => {
                      if (!checkDocumentStatus()) {
                        triggerFileSelection();
                      }
                    }}
                    sx={{
                      background:
                        "linear-gradient(94deg, #40811E 0.13%, #172A0D 100.13%)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "darkgreen",
                      },
                    }}
                  >
                    SELECT FILE
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
            <ErrorDialog
              open={isErrorDialogOpen}
              handleClose={handleDialogClose}
              title={titleDialogMessage}
              message={errorMessage}
            />
            <SuccessDialog
              open={isSuccessDialogOpen}
              handleClose={handleDialogSuccessClose}
              title={titleDialogMessage}
              message={successMessage}
              redirectUrl="/face-recognition"
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Documents;
